/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import Select from "react-select";
import { BASECONFIG } from "../../config";
import { useNavigate } from "react-router-dom";

import {
    isValid,
    validateCountryCode,
    validateMobile,
} from "../../validation/input-validation";
import { API } from "../../apiwrapper";
import { useDispatch, useSelector } from "react-redux";
import { SetloaderData } from "../../store/reducer";
import VerifiOTP from "../modal/verify-otp";
import ResponseMessage from "../modal/response-message";

const VerifyPhone = () => {
    const auth = useSelector((state) => state.authUser);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [isVerifiOTP, setIsVerifiOTP] = useState(false);

    const { fcmToken, countryCodes } = useSelector((state) => state);
    const [showModal, setShowModal] = useState(false);
    const [respMessage, setRespMessage] = useState({
        title: "",
        message: "",
        status: "",
    });
    const [inpField, setInpField] = useState({
        mobile: "",
        countryCode: "",
        roleID: "642ae2caa25d6e5933d6bbb5",
    });
    const [loading, setLoading] = useState(false);


    const formatOptionLabel = ({ value, label, flag }) => (
        <div>
            <img
                src={flag}
                alt="Flag"
                style={{ borderRadius: "50%", height: "20px", marginRight: "10px" }}
            />
            {label}
        </div>
    );

    const navigate = useNavigate();

    const {
        mobile = "",
        countryCode = "91",
        roleID = "640f32238095761c85f2bed8",
        email = auth?.data?.email
    } = inpField;

    const handleChange = (e) => {
        setInpField({
            ...inpField,
            [e.target.name]: e.target.value,
        });
        handleValidate(e);
    };

    const handleValidate = (e) => {
        const errors1 = {};
        switch (e.target.name) {
            case "mobile":
                errors1.mobile = validateMobile(e.target.value);
                break;
            case "countryCode":
                errors1.countryCode = validateCountryCode(e.target.value);
                break;
            default:
                break;
        }
        setErrors(errors1);
    };

    const validateAll = () => {
        let err1 = {};
        err1.mobile = validateMobile(mobile);
        err1.countryCode = validateCountryCode(countryCode);
        return err1;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let err = validateAll();
            if (isValid(err)) {
                const redirectPath = "/leaderboard";
                navigate(redirectPath);
                localStorage.removeItem("redirectPath");
            } else {
                setErrors(err);
            }
            setLoading(false);
        } catch (error) {
            toast(error, { type: "error" });
        } finally {
            dispatch(SetloaderData(false));
        }
    };

    const handleGetOTP = async (e) => {
        e.preventDefault();
        let contiBTN = document.getElementById("Continue");
        try {
            let err = validateAll();
            if (isValid(err)) {
                let url = "/v1/user/otp/send";
                dispatch(SetloaderData(true));
                contiBTN.disabled = true;
                await API({
                    base_url: BASECONFIG.BASE_URL_USER,
                    url: url,
                    method: "POST",
                    body: {
                        mobileNumber: `+${countryCode}-${mobile}`,
                        email: email,
                        isVerify: true
                    },
                    formData: false,
                }).then((data) => {
                    if (data?.status || data?.status === true) {
                        setRespMessage({
                            title: "Create Account",
                            message: data?.message,
                            status: data?.status,
                        });
                        handleClose();
                        setTimeout(() => {
                            setShow(true);
                        }, 100);
                    } else {
                        setRespMessage({
                            title: "Create Account",
                            message: data?.message,
                            status: data?.status,
                        });
                        setShowModal(true);
                    }
                });
            } else {
                setErrors(err);
            }
        } catch (error) {
            throw error;
        } finally {
            contiBTN.disabled = false;
            dispatch(SetloaderData(false));
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setShow(false);
        setRespMessage({
            title: "",
            message: "",
            status: "",
        });
    };

    return (
        <>
            <main class="main-middle-content-section header-custom-main">
                {show && (
                    <VerifiOTP
                        show={show}
                        handleClose={() => setShow(false)}
                        countryCode={countryCode}
                        mobileNumber={mobile}
                        email={email}
                        setIsVerifiMob={setIsVerifiOTP}
                        fetchUserData={(e) => handleSubmit(e)}
                    />
                )}

                {showModal ? (
                    <ResponseMessage
                        show={showModal}
                        handleClose={() => handleClose()}
                        title={respMessage.title}
                        message={respMessage.message}
                        status={respMessage.status}
                    />
                ) : (
                    ""
                )}

                <div class="container-fluid container-xl">
                    <div class="login-input-content-sec row">
                        <div class="login-content-sec col-md-6">
                            <h4>Verify Phone Number</h4>
                            <p>Enter phone number below to verify.</p>

                            <div class="input-section-main">
                                <div><Select
                                    placeholder="Select Country Code "
                                    options={
                                        countryCodes?.data?.length
                                            ? countryCodes?.data?.map((code) => ({
                                                value: code?.phone?.countryCode || "",
                                                label:
                                                    `${code?.info?.alpha3} +${code?.phone?.countryCode}` ||
                                                    "",
                                                flag: code?.info?.flag || "",
                                            }))
                                            : []
                                    }
                                    name="countryCode"
                                    formatOptionLabel={formatOptionLabel}
                                    classNamePrefix="countryCode"
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onBlur={handleValidate}
                                    onChange={(option) =>
                                        setInpField({
                                            ...inpField,
                                            countryCode: option.value,
                                        })
                                    }
                                />
                                    <span className="text-danger">{errors.countryCode}</span>
                                </div>

                            </div>

                            <div class="input-section-main">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Enter the Mobile"
                                        className="input[type=number]::-webkit-outer-spin-button form-control"
                                        name="mobile"
                                        value={mobile}
                                        onBlur={handleValidate}
                                        onChange={(e) => {
                                            const re = /^[0-9]{0,10}$/;
                                            const inputValue = e.target.value;
                                            if (inputValue === "" || re.test(inputValue)) {
                                                if (e.target.value.includes(" ")) {
                                                    e.target.value = inputValue.replace(" ", "");
                                                }
                                                handleChange(e);
                                            }
                                        }}
                                        onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                    />

                                    <span className="text-danger">{errors.mobile}</span>
                                </div>
                            </div>


                            <button
                                className="login-page-btn"
                                onClick={handleGetOTP}
                                disabled={loading}
                                id="Continue"
                            >
                                {loading ? "Please Wait..." : "Continue"}
                            </button>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <figure class="col-md-6 rt-img-login-sec">
                            <img src="../Image/signup.png" />
                        </figure>
                    </div>
                </div>
            </main>

        </>
    );
};

export default VerifyPhone;