import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import {
  authUser,
  Loader,
  countryCodes
} from "./reducer";
import logger from "redux-logger";

const reducer = {
  authUser,
  Loader,
  countryCodes
};

const rootReducer = combineReducers(reducer);
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
let middleware = [
  ...getDefaultMiddleware({ serializableCheck: false }),
  logger,
];

if (process.env.NODE_ENV === "production") {
  middleware = [...getDefaultMiddleware({ serializableCheck: false })];
}

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);
export default store;