/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { BASECONFIG } from "../../config";
import { API } from "../../apiwrapper";
import apiURl from "../../store/actions/api-url";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SetPasswordToken } from "../../store/reducer/password-token";
import { Link, useNavigate } from "react-router-dom";
import { SetloaderData } from "../../store/reducer";
import { isValid, validateEmail } from "../../validation/input-validation";

const RecoverPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const validateAll = () => {
        let err1 = {};
        err1 = validateEmail(email);
        return err1;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let submitBTN = document.getElementById("submitRPas");
        try {
            let err = validateAll();
            if (isValid(err)) {
                let url = apiURl.resetMail;
                let payload = { email: email };
                submitBTN.disabled = true;
                dispatch(SetloaderData(true));
                await API({
                    base_url: BASECONFIG.BASE_URL_USER,
                    url: url,
                    method: "POST",
                    body: payload,
                    formData: false,
                }).then((data) => {
                    if (data?.status || data?.status === "true") {
                        toast.success(data?.message);
                        dispatch(SetPasswordToken(data?.data));
                        localStorage.setItem("data", data?.data);
                        navigate("/");
                        setEmail("");
                        setErrorMessage("");
                    } else {
                        setErrorMessage(data?.message);
                    }
                });
            } else {
                setErrorMessage(err);
            }
        } catch (error) {
            throw error;
        } finally {
            submitBTN.disabled = false;
            dispatch(SetloaderData(false));
        }
    };

    return (
        <>

            <main class="main-middle-content-section header-custom-main">
                <div class="container-fluid container-xl">
                    <div class="login-input-content-sec row">
                        <div class="recover-pass-content-sec col-md-6">
                            <h4 class="">Recover Password</h4>
                            <p>Enter your details below.</p>
                            <div class="input-section-main">
                                <input
                                    value={email}
                                    type="email"
                                    placeholder="Email address *"
                                    className="form-control"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setErrorMessage("");
                                    }}
                                />
                                {errorMessage && (
                                    <span className="text-danger" style={{ fontSize: "16px" }}>
                                        {errorMessage}
                                    </span>
                                )}
                            </div>
                            <div class="section-speretor">
                                <Link to={"/login"} className="login-back-btn">
                                    Back
                                </Link>
                                <button className="login-page-btn" onClick={handleSubmit} id="submitRPas">
                                    Recover Password
                                </button>
                            </div>


                        </div>
                        <figure class="col-md-6 rt-img-login-sec">
                            <img src="Image/login-image.png" />
                        </figure>
                    </div>

                </div>
            </main>
        </>
    );
};

export default RecoverPassword;
