import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Homepage = () => {
	return(
		<>
			<header class="header-custom-main">
				<div class="top-banner-sec">
					<div class="container-fluid container-xl banner-content-sec d-flex">
						<div class="banner-text-sec">
							<h1>Welcome to Mabrook's Reward Program</h1>
							<p>Embark on a journey of financial growth and empowerment with our reward program. Mabrook is excited to present an exclusive opportunity to reward your commitment and enthusiasm. As a Mabrook member, you will enjoy an array of benefits, exclusive perks, and a thriving community that shares your passion for financial innovation.</p>
						</div>
						<figure class="banner-img-sec">
							<img src="Image/landingpage-girl.png"/>
						</figure>
					</div>
				</div>
			</header>
			<main class="main-middle-content-section mobile-cus-p pt-5 pb-5">
				<div class="container-fluid container-xl">
					<div class="why-joinus-content-sec mt-5 mb-5 pb-5 pt-5">
						<div class="joinus-content-sec mt-5">
							<h4>WHY JOIN MABROOK REWARDS</h4>
							<h2>Share your Happiness!</h2>
							<p>At Mabrook, we're committed to providing Shariah-complaint and rewarding financial opportunities. By referring friends and family, grow your wealth and support our mission of bringing Shariah crowdfunding and investment opportunities a step closer to everyone you love. It's time to earn while making a positive impact!							</p>
						</div>
						<figure>
							<img src="Image/3.png"/>
						</figure>
					</div>

					<div class="why-joinus-content-sec mobile-cus-flex mt-5 mb-5 pb-5 pt-5">
						<figure>
							<img src="Image/4.png"/>
						</figure>
						<div class="joinus-content-sec mt-5">
							<h4>WHO ARE REWARDED</h4>
							<h2>Earn and Grow Your Rewards</h2>
							<p>Mabrook introduces a unique opportunity to supercharge your financial journey. With our Referral Program, you earn exciting cash rewards, those who secure a place among the top 5 will enjoy the benefits of their outstanding performance.</p>
						</div>
					</div>
				</div>
			</main>
		</>
	)
};

export default Homepage;