import React, { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";

import { useSelector } from "react-redux";

const LoaderComponent = () => {
    const [loading, setLoading] = useState(false);

    const { Loader } = useSelector((state) => state);
    useEffect(() => {
        setLoading(Loader?.data);
    }, [Loader]);

    return (
        <>
            {" "}
            {loading ? (
                <div
                    className="alter-gif-loader"
                    style={{ display: loading ? "block" : "none" }}
                >
                    <Loading
                        loading={loading}
                        background="rgba(0,0,0,0.8)"
                        loaderColor="#3498db"
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default LoaderComponent;
