/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import { useState } from "react";
import apiURl from "../../store/actions/api-url";
import { toast } from "react-toastify";
import { API } from "../../apiwrapper";
import { BASECONFIG } from "../../config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    isValid,
    validateCPassWord,
    validatePassWord,
} from "../../validation/input-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ChangePassword = () => {
    let regexNum = /^(?=.*[0-9])/;
    let regexSmlChar = /^(?=.*[a-z])/;
    let regexUprChar = /^(?=.*[A-Z])/;
    // let regexSpclChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let regexSpclChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,<>.\/?~])/;

    const [inpField, setInpField] = useState({
        password: "",
        confirmpassword: "",
    });
    // const { data } = useSelector((state) => state.passwordToken);

    let data = localStorage.getItem("data");

    console.log("data1", data);

    const navigate = useNavigate();

    const { password = "", confirmpassword = "" } = inpField;

    const [errors, setErrors] = useState({});

    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");

    const [userRoles, setUserRoles] = useState([]);
    const [user, setUser] = useState([]);
    const { fcmToken } = useSelector((state) => state);

    // const handleChange = (e) => {
    //   setInpField({
    //     ...inpField,
    //     [e.target.title]: e.target.value,
    //   });
    // };

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    const toggleNewPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text");
            return;
        }
        setConfirmPasswordType("password");
    };

    const handleChange = (e) => {
        if (e.target.type == "checkbox") {
            setInpField({
                ...inpField,
                [e.target.name]: e.target.checked,
            });
        } else {
            setInpField({
                ...inpField,
                [e.target.name]: e.target.value,
            });
        }
        handleValidate(e);
    };

    const handleValidate = (e) => {
        const errors1 = {};
        switch (e.target.name) {
            case "password":
                errors1.password = validatePassWord(e.target.value);
                break;
            case "confirmpassword":
                errors1.confirmpassword = validateCPassWord(e.target.value, password);
                break;
            default:
                break;
        }
        setErrors(errors1);
    };

    const validateAll = () => {
        let err1 = {};
        err1.password = validatePassWord(password);
        err1.confirmpassword = validateCPassWord(confirmpassword, password);
        return err1;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let err = validateAll();
            if (isValid(err)) {
                let payload = {
                    ...inpField,
                    token: data,
                };
                let url = apiURl.resetPassword;
                await API({
                    base_url: BASECONFIG.BASE_URL_USER,
                    url: url,
                    method: "POST",
                    body: payload,
                    formData: false,
                }).then((data) => {
                    console.log("Data>>>>>>>", data);
                    if (data?.status || data?.status === "true") {
                        toast.success(data?.message);
                        navigate("/login");
                    } else {
                        toast.error(data?.message);
                    }
                });
            } else {
                setErrors(err);
            }
        } catch (error) {
            toast(error, { type: "error" });
        }
    };
    return (
        <>
            <main class="main-middle-content-section header-custom-main">
                <div class="container-fluid container-xl">
                    <div class="login-input-content-sec row">
                        <div class="change-pass-content-sec col-md-6">
                            <h4 class="">Reset Password</h4>
                            <p>Enter your details below.</p>
                            <div class="input-section-main">
                                <input
                                    type={passwordType}
                                    placeholder="Password"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={password}
                                    name="password"
                                    onKeyPress={(event) => {
                                        if (event.key === " ") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {errors.password ? (
                                    <>
                                        {" "}
                                        <span className="text-danger" style={{ fontSize: "14px" }}>
                                            {errors.password}
                                        </span>
                                        <ul>
                                            <li
                                                className={
                                                    password.length >= 8 ? "text-success" : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {password.length >= 8 && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}
                                                8 characters
                                            </li>

                                            <li
                                                className={
                                                    regexUprChar.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexUprChar.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Uppercase{" "}
                                            </li>
                                            <li
                                                className={
                                                    regexSmlChar.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexSmlChar.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Lowercase
                                            </li>
                                            <li
                                                className={
                                                    regexNum.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexNum.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Number{" "}
                                            </li>
                                            <li
                                                className={
                                                    regexSpclChar.test(password)
                                                        ? "text-success"
                                                        : "text-danger"
                                                }
                                                style={{ fontSize: "14px" }}
                                            >
                                                {regexSpclChar.test(password) && (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                )}{" "}
                                                1 Special character
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div class="input-section-main">
                                <div className="passwordIcon">
                                    <input
                                        type={confirmPasswordType}
                                        placeholder="Confirm Password"
                                        className="form-control"
                                        onChange={handleChange}
                                        name="confirmpassword"
                                        value={confirmpassword}
                                        onKeyPress={(event) => {
                                            if (event.key === " ") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    <span className="text-danger">{errors.confirmpassword}</span>
                                </div>
                                <div class="section-speretor">
                                    <button className="login-page-btn" onClick={handleSubmit}>
                                        Change Password
                                    </button>
                                </div>
                            </div>
                        </div>
                        <figure class="col-md-6 rt-img-login-sec">
                            <img src="Image/login-image.png" />
                        </figure>
                    </div>
                </div>
            </main>

        </>
    );
};

export default ChangePassword;
