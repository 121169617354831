/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

function ResponseMessage({
    show,
    handleClose,
    title,
    message,
    status,
    otherLogin = false,
}) {
    const navigate = useNavigate();
    const handleCloseModel = () => {
        handleClose();
        if (otherLogin) {
            navigate("/");
        }
    };
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleCloseModel();
        }, 100000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [handleClose]);
    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                keyboard="false"
                onHide={() => handleCloseModel()}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"text-center"}>
                        <img
                            src={status ? "../Image/tickSucess.png" : "../Image/info_2.png"}
                            className={`my-2`}
                            alt=""
                            width={80}
                            height={80}
                        />
                        <p
                            className={`${status ? "text-success" : "text-danger"
                                } text-center`}
                        >
                            {message}
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {otherLogin && status && (
                        <Button variant="primary" onClick={() => navigate("/settings")}>
                            Ok
                        </Button>
                    )}
                    <Button variant="secondary" onClick={() => handleCloseModel()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ResponseMessage;
