import { createSlice } from "@reduxjs/toolkit";

export const sliceFcmToken = createSlice({
  name: "fcmToken",
  initialState: {
    data: [],
  },
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
  },
});

const { fetched } = sliceFcmToken.actions;

export const setFcmToken = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(fetched({ data }));
};

export default sliceFcmToken.reducer;
