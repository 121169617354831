const Privacypolicy = () => {
  return (
    <>
      <section id="privacyBanner" className="pb-5 header-custom-main">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="privacyBanner1">
                <h4>Privacy Policy</h4>
              </div>
            </div>
            <div className="col-md-6">
              {/* <img src="Image/Familypicture2.png" alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="privacyPolicySection header-custom-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="privacyPolicyHeading">Privacy Policy</h1>
              <p className="addressALM mt-3">
                Al Mabrook Financials LLC is located in Canada at
              </p>
              <p className="addressALM">130 Queens Quay East, Suite 1018, Toronto, Ontario,</p>
              <p className="addressALM">Canada M5A 0P6</p>
              <p className="addressALM">PHONE +1 (437) 553 - 7017</p>
              <p className="addressALM">Email address: <a href="mailto:support@almabrook.io">support@almabrook.io</a></p>
              <h5 className="cookies mt-5">What We Do</h5>
              <p className="paratermsCondition mt-3">
                Mabrook aims to provide staking, vesting and crowdfunding
                opportunities for small to medium scale self-service investors
                and start-ups in Canada. The platform will support crypto
                currency-based investment. For the current pilot version of the
                website, investment options are not active. We will be updating
                the privacy policy continuously as the functionality of the
                website will be enhanced.
              </p>
              <h5 className="cookies mt-5">
                What type of information we store?
              </h5>
              <p className="paratermsCondition mt-3">
                {" "}
                The current pilot version showcases the proof of concept (POC),
                and the next phase will offer ICO (Initial Coin Offering) and
                token sale. During these phases the platform will not save any
                personal data of the user, nor will the user need to register or
                provide any personal information.
              </p>
              <p className="paratermsCondition mt-3">
                The platform offers to subscribe to the wait list with email
                address, name and contact number. However, this is not
                mandatory. In the future, if you register for an account, we may
                ask for your contact information, including items such as name,
                company name, address, email address, and telephone number.
              </p>
              <h5 className="cookies mt-5">
                What do we do with the information?
              </h5>
              <p className="paratermsCondition mt-3">
                Our platform is based on the formidable blockchain technology,
                that ensures each client is anonymous on the chain. Your
                personal data is secured. The information entered at the time of
                subscribing is kept and is assured that no information is shared
                with any other application or third-party service providers.
                This information is used by Mabrook to inform the interested
                users about our service's launch and upcoming projects.
              </p>
              <p className="paratermsCondition mt-3">
                Emails and contact numbers are used to send the relevant
                notifications only, and is not used for any sort of marketing
                campaigns unless, otherwise confirmed prior to the campaign.
              </p>
              <h5 className="cookies mt-5">Get in Touch</h5>
              <p className="paratermsCondition mt-3">
                In case of any query or complaint, please get in touch with us
                at <a href="mailto:support@almabrook.io">support@almabrook.io</a>
              </p>
              <p className="paratermsCondition mt-3">Updated February 2023</p>
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Privacypolicy;
