import { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	const [open, setOpen] = useState(false);
	const handleClick = () => {
		setOpen(!open);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};
	return(
		<footer class="footer-main">
			<div class="container-fluid container-xl  footer-content-sec">
				<div class="footer-logo-sec cus-pt-5">
					<figure>
						<img src="../Image/footerLogo.png" />
					</figure>
					<p>
						Mabrook is the world's first Shariah-compliant asset
						tokenization platform built on blockchain technology. We aim to
						provide safe, efficient, and ethical crowdfunding opportunities
						for our community members in Canada.
					</p>
					<p class="cus-pb-5">
						The name "Mabrook" is derived from the Arabic word "Barakah,"
						which is used to express a desire for increased financial
						blessings, protection against financial loss or difficulties,
						and faith in continuing ongoing rewards.
					</p>
				</div>
				<hr class="footer-line deskstop-show"/>
				<div class="footer-nav-sec cus-pt-5">
					<h3>Community</h3>
					<ul>
						<li><a href="https://www.linkedin.com/company/al-mabrook/" target="_blank"> LinkedIn</a></li>
						<li><a href="https://www.facebook.com/Al-Mabrook-108314995316911/" target="_blank">Facebook </a></li>
						<li><a href="https://www.instagram.com/mabrook.io/" target="_blank">Instagram</a></li>
						<li><a href="https://t.me/almabrookannouncements/" target="_blank">Telegram</a></li>
					</ul>
				</div>
			</div>
			<hr class="footer-line" />
			<div class="mobile-postion-set">
				<div class="container-fluid container-xl footer-content-sec">
					<div class="footer-logo-sec">
						<h3 class="mb-3 mt-5 pt-2">Contact us</h3>
						<p>Do you have any questions? Below you can find our contact details. 130 Queens Quay East, Suite 1018, Toronto, Ontario, Canada M5A 0P6</p>
					</div>

					<hr class="footer-line deskstop-show mobile-line-cus-set"/>

					<div class="footer-nav-sec custom-mobile-set-nav mt-5 pt-4">
						<ul>
							<li>
								<Link to={"/privacyPolicy"} class="border-rt-nav pr-4" onClick={handleClick} style={{ cursor: "pointer" }}>
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link to={"/termsCondition"} onClick={handleClick} style={{ cursor: "pointer" }}>
									Terms & Conditions
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<hr class="footer-line deskstop-show mobile-line-cus-set"/>
				<div class="container-fluid container-xl footer-content-sec">
					<div class="footer-logo-sec custom-mobile-set-mail pt-4 cus-pb-5">
						<a  href="mailto:support@almabrook.io">E-mail: support@almabrook.io</a> <span class="ml-3 mr-3 middle-line-sm mobile-none"> | </span><a href="">Mobile: +1 (437) 553 - 7017</a>
					</div>
					<div class="footer-nav-sec pt-4 cus-pb-5 cus-pb-5-m">
						<p>© 2024 All Rights Reserved.</p>
					</div>
				</div>
			</div>
		</footer>

	)
};

export default Footer;