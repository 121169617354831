import { createSlice } from "@reduxjs/toolkit";

export const slicePasswordToken = createSlice({
  name: "passwordToken",
  initialState: {
    // data: [],
    data: null,
  },
  // reducers: {
  //   fetched: (state, { payload }) => {
  //     state.data = payload.data;
  //   },
  // },
  reducers: {
    fetched: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

const { fetched } = slicePasswordToken.actions;

export const SetPasswordToken = (data) => async (dispatch) => {
  if (!data) throw Error("missing data parameter");
  dispatch(fetched({ data }));
};

export default slicePasswordToken.reducer;
