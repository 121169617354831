import { useState } from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LogoutUser, SetAuthUserData } from "../../store/reducer/auth-user";
import { LogoutGoogleUser } from "../../store/reducer/auth-google-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faHome,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("token") || "";
    const location = useLocation();

    const { authUser } = useSelector((state) => state);

    const handleLogout = () => {
        dispatch(SetAuthUserData({}));
        dispatch(LogoutUser({}));
        dispatch(LogoutGoogleUser({}));
        localStorage.clear();
        navigate("/");
    };
    return(
        <>
            <div class="scrolled-top-header">
                <div class="container-fluid container-xl custom-top-header">
                    <a class="navbar-brand" href="index.html">
                        <Link className="" to="/">
                            {token ? location.pathname == "/" ? <img src="../Image/logo.png" alt="" className="deskstop-show img-2" width="40" /> : "" : ""}
                            <img src="../Image/logoIcon.png" alt="" className={token ? location.pathname == "/" ? "mobile-none" : "img-1" : "img-1"} />
                        </Link>
                    </a>
                    <div class="right-btn-sec logout-rt-top-sec">
                        {!token ? (
                            <>
                                {
                                    location.pathname == "/register" ? <span class="font-size-16">Already have an account?</span> :
                                        <Link class="sign-up-btn mr-3" to={"/register"} onClick={() => { setOpen(false); }}>
                                            Sign Up
                                        </Link>
                                }
                                <Link class="login-btn"
                                    to={"/login"}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    Log In
                                </Link>
                            </>
                        ) : (
                            <>
                                {
                                    location.pathname == "/" ?
                                        <Link class="leaderboard-btn mr-3" to={"/leaderboard"} onClick={() => { setOpen(false); }}>
                                            &nbsp;<img src="../Image/tropy-win.svg" /> Leaderboard  &nbsp;&nbsp;
                                        </Link>
                                        : ""
                                }
                                <span className="mobile-none">{authUser?.data?.firstName + " " + authUser?.data?.lastName}</span>
                                <button>
                                    {authUser?.data?.firstName?.charAt(0) + authUser?.data?.lastName?.charAt(0)}
                                </button>
                                <button>
                                    <Link to={"/"} onClick={handleLogout}><img src="../Image/logout.svg" /></Link>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
};

export default Header;
