export const validationMessages = {
    deleteConfirm: "Are you sure you want to delete this item?",
    changeStatus: "Are you sure you want to do this?",
    require: "This field is required.",
    unableToLogin: "Unable to login. Please try after some time.",
    name: `Name is required.`,
    fName: `First name is required. `,
    lName: `Last name is required. `,
    allowAlphabets: `This field allow alphabets only.`,
    emailReq: `Email is required.`,
    validEmail: `Enter valid Email.`,
    passwReq: `Password is required.`,
    validPass: `Password must included atleast.`,
    cpasswReq: `Confirm Password is required.`,
    currentasswReq: `Current Password is required.`,
    notMatchPassw: `Password and Confirm Password should be same.`,
    phoneReq: `Contact number is required.`,
    validMobile: `Please enter a valid mobile number.`,
    reqEnquiryType: "Enquiry type is required.",
    reqQueryMessage: "Query message is required.",
    allowMax8: "Allow max 8 characters.",
    Time_from: "From time is required.",
    otpEnter: "Please enter OTP.",
    validOtp: `Please enter a valid OTP.`,
    invalTime: "Please enter a valid time.",
    sameTime: "To time should be greater than Form time.",
    country: "Country is required.",
    province: "Province is required.",
    city: "City is require.d",
    streetNumber: "Street Number is required.",
    streetName: "Street Name is required.",
    postalCode: "Postal Code is required.",
    pep: "This field is required.",
    first_name: "This Field is Required.",
    countryCode: "Country code is required."
  };
  const apiURl = {
    getLink: "/v1/links",
    createLogin: "/v1/common/otherLogin",
    resetMail: "/v1/user/mail/reset",
    resetPassword: "/v1/user/password/reset",
    getLinkedInProfile: "/linkedinLogin",
    getToken: "/v1/common/linkedin/token",
    getCollection: "/v1/projects",
    updateUser: "/v1/user",
    createUpdates: "/v1/updates",
    getUpdatesById: "/v1/updates",
    updatesId: "/editUpdates",
    shareRegister: "/v1/tokens/shareRegister",
    getActiveCampaign: "/v1/referral/campaign/getActive",
  };
  
  export default apiURl;
  