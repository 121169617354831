import { useState } from "react";
import { Link } from "react-router-dom";

const SignupCompleted = () => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<main class="main-middle-content-section header-custom-main">
				<div class="container-fluid container-xl">
					<div class="login-input-content-sec row">
						<div class="verify-content-sec col-md-6">
							<h4>Thanks, now you need to verify your e-mail</h4>
							<p>Check your e-mail address we send you an e-mail with verification link. Click and try login to Mabrook account.</p>
							<Link class="login-page-btn login-page-btn-signup custom-padding"
								to={"/login"}
								onClick={() => {
									setOpen(false);
								}}>
								Login
							</Link>
						</div>
						<figure class="col-md-6 rt-img-login-sec">
							<img src="Image/signup.png" />
						</figure>
					</div>
				</div>
			</main>
		</>
	);
};

export default SignupCompleted;
