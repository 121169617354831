import { BASECONFIG } from "../config";

const api = async ({
  base_url,
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  headers["Access-Control-Allow-Origin"] = "*";
  headers.Authorization = `${localStorage.getItem("token") || ""}`;
  if (!formData) {
    headers["Content-Type"] = "application/json";
  }

  try {
    return await fetch(base_url + url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    })
      .then((response) => response.clone().json())
      .then((data) => data);
  } catch (error) {
    throw Error(error);
  }
};

export const getAwsImageUrl = async (image, name = "awsImgUrl") => {
  try {
    if (typeof image === "string") {
      return image;
    }
    let fd = new FormData();
    fd.append("image", image);
    let imgUrl = await allApi({
      url: `${BASECONFIG.AWS_URL}/${name}`,
      method: "POST",
      formData: true,
      body: fd,
    }).then((data) => {
      // return (
      //   data?.data?.image.map((ele) => {
      //     const [key, value] = Object.entries(ele)[0];
      //     return { [key]: value?.Location };
      //   }) || []
      // );
      return data?.data?.image?.Location;
    });
    return imgUrl;
  } catch (error) {
    throw Error(error);
  }
};

export const allApi = async ({
  body,
  headers = {},
  method,
  signal,
  url,
  formData = false,
}) => {
  // headers['Access-Control-Allow-Origin'] = '*';

  try {
    return await fetch(url, {
      method,
      headers,
      body: body ? (formData ? body : JSON.stringify(body)) : null,
      signal,
    })
      .then((response) => response.clone().json())
      .then((data) => data);
  } catch (error) {
    throw Error(error);
  }
};

export const getMultipleAwsImageUrl = async (image, name = "awsImgUrl") => {
  try {
    if (typeof image === "string") {
      return image;
    }
    let fd = new FormData();

    if (Object.values(image || {})?.length > 1) {
      Object.values(image || {})?.map((img) => {
        fd.append("image", img);
      });
    }

    // let fd = new FormData();
    // fd.append("image", image);
    let imgUrl = await allApi({
      url: `${BASECONFIG.AWS_URL}/${name}`,
      method: "POST",
      formData: true,
      body: fd,
    }).then((data) => {
      return (
        data?.data?.image?.map((ele) => {
          const [key, value] = Object.entries(ele)[0];
          return value?.Location;
        }) || []
      );
      // return data?.data?.image?.Location;
    });
    return imgUrl;
  } catch (error) {
    throw Error(error);
  }
};

export default api;