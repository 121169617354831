import { createSlice } from "@reduxjs/toolkit";

export const sliceAuthGoogleUser = createSlice({
  name: "authgoogleuser",
  initialState: {
    data: null, // changed the initial state to null
  },
  reducers: {
    fetched: (state, action) => {
      state.data = action.payload.data;
    },
    logout: (state) => {
      state.data = null; // sets the data to null when the user logs out
    },
  },
});

export const { fetched, logout } = sliceAuthGoogleUser.actions;

export const SetAuthGoogleUserData = (data) => (dispatch) => {
  // if (!data) throw Error("missing Data parameter");
  dispatch(fetched({ data }));
};

export const LogoutGoogleUser = () => (dispatch) => {
  dispatch(logout({}));
};

export default sliceAuthGoogleUser.reducer;
