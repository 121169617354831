import React from "react";
import { useNavigate } from "react-router-dom";

const Mailverification = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/login");
  };

  return (
    <>
      <section className="loginPage header-custom-main">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div>
                <h3 className="mb-2 mt-3">Thanks for verifying your e-mail </h3>
                <div className="my-3">
                  {/* <p>
                    Check your e-mail address we send you an e-mail with
                    verification link. Click and try login to Mabrook account.
                  </p> */}
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-primary borderRadius"
                    style={{ width: "100%" }}
                    onClick={navigateToHome}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <figure className="signupGirlImg">
                <img src="Image/signup.png" className="img-fluid" alt="" />
                <div className="tickSucess">
                  <img src="Image/tickSucess.png" alt="" className="" />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Mailverification;
