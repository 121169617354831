import { createSlice } from "@reduxjs/toolkit";

export const slicecountryCodes = createSlice({
  name: "countryCodes",
  initialState: {
    data: [],
    disableCountry: [],
  },
  reducers: {
    fetched: (state, { payload }) => {
      state.data = payload.data;
    },
    disableCountry: (state, { payload }) => {
      console.log("-----Reducer ActiveModule Called --->");
      state.disableCountry = payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
const { fetched,disableCountry } = slicecountryCodes.actions;

export const SetCountryCodes = (data) => async (dispatch) => {
  dispatch(fetched({ data }));
};

export const SetDisableCountry = (data) => async (dispatch) => {
  if (!data) throw Error("missing Data parameter");
  dispatch(disableCountry({ data }));
};
export default slicecountryCodes.reducer;
